<template>
  <div class="page container">
    <div class="page-title mt-5">
      <h1>メールアドレス確認完了</h1>
      <p>主たる営業担当者様のメールアドレスを確認完了致しました。</p>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="card biz-primary mt-3">
          <div class="card-header">
            パスワードを設定してください。
            <p>パスワードを設定してください。</p>
          </div>
          <div class="card-body">
            <div class="biz-form-group">
              <label class="biz-form-label">メールアドレス</label>
              <div class="biz-form-body">
                <div class="row">
                  <div class="col-5">
                    <b-form-group id="input-group-1" class="biz-input-group">
                      <b-form-input v-model="form.email" type="email" disabled>
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!form.hasPassword">
              <hr/>
              <div class="biz-form-group" v-if="!form.hasPassword">
                <label class="biz-form-label required">パスワード</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-5">
                      <validation-provider class="mt-2" name="パスワード" :rules="{required: true, passwordStrength: true }" v-slot="{ errors }">
                        <b-form-group id="input-group-1" class="biz-input-group">
                          <b-input-group>
                            <b-form-input v-model="form.password" :type="passwordVisible?'text':'password'" required placeholder="例) XXXXXX">
                            </b-form-input>
                            <b-input-group-addon>
                              <j-button buttonType="button" @click="passwordVisible=!passwordVisible" class="no-min btn-light-link">
                                <font-awesome-icon icon="eye"/>
                              </j-button>
                            </b-input-group-addon>
                          </b-input-group>
                        </b-form-group>
                        <pre class="invalid-feedback d-inline override-font">{{ errors[0] }}</pre>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
              <div class="biz-form-group" v-if="!form.hasPassword">
                <label class="biz-form-label required">パスワード（再）</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-5">
                      <validation-provider class="mt-2" name="パスワード（再）" :rules="{required: true, passwordStrength: true }" v-slot="{ errors }">
                        <b-form-group id="input-group-1" class="biz-input-group">
                          <b-input-group>
                            <b-form-input v-model="form.passwordAgain" :type="passwordAgainVisible?'text':'password'" required placeholder="例) XXXXXX">
                            </b-form-input>
                            <b-input-group-addon>
                              <j-button buttonType="button" @click="passwordAgainVisible=!passwordAgainVisible" class="no-min btn-light-link">
                                <font-awesome-icon icon="eye"/>
                              </j-button>
                            </b-input-group-addon>
                          </b-input-group>
                        </b-form-group>
                        <pre class="invalid-feedback d-inline override-font">{{ errors[0] }}</pre>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="form.hasPassword">
              <b-alert variant="success" show>パスワード設定済みです。</b-alert>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <j-button buttonType="submit" variant="primary" class="mt-2" size="lg">
            次へ
          </j-button>
          <br />
          <j-button variant="light" class="mt-4" to="/initiate/policy">
            <font-awesome-icon class="va-m" icon="arrow-left"></font-awesome-icon>
            ホームへ戻る
          </j-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<style lang="scss" scoped>

</style>
<script>
  import Init from '@/model/Init.js'
  
  export default {
    components: {
      
    },
    data: () => ({
      form: {},
      passwordVisible: false,
      passwordAgainVisible: false
    }),
    mounted: function(){
      Init.confirm(this.$route.params.authCode).then(result => {
        if(!result.data) return this.$router.push("/");
        this.$data.form = result.data;
        if(this.$data.form){
          this.$data.form.passwordAgain = this.$data.form.password;
        }
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    methods: {
      onSubmit(){
        if(this.form.password === this.form.passwordAgain){
          Init.setupPassword(this.form).then(result => {
            this.$store.commit("login", {
              accessToken: result.headers.authorization,
              user: result.data
            });
            this.$store.commit("setPublicAuthCode", {
              authCode: this.$route.params.authCode,
            });
            this.$router.push({
              path: "/initiate/registration",
              query: { corporationNumber: this.$data.form.corporateNumber }
            });
          });
        }else{
          this.$errorMessage(undefined, "入力エラーが発生しました。", "パスワードとパスワード（再）が合わないです。");
        }
      },
      onOpenSearchDialog(){

      }
    }
  }
</script>